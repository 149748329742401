<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/banner/Operations.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Operational Success</div>
            <h1>Operational Success</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Operational Success</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
				<div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Operational Success</div>
							<h2 class="display-4 font-weight-bold">EMPOWERING OPERATIONS TEAMS.</h2>
							<p>
                                Performance is the underlying foundation that drives eFlexervices. We empower you to focus on day-to-day functions so you can focus on growing your business. You can trust us to create and implement strategies that minimize pain points.
                            </p>
							<router-link to="/contact"><a class="button button-rounded button-large nott ls0">Get Started</a></router-link>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/header/Operations.jpg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
							<p>It’s easier to get things done with trained team members who can manage and optimize details that keep your business running smoothly. eFlex delivers services that enable other departments in your company to do their job with efficiency and effectiveness to meet your customers’ needs. </p>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/operations1.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Assistance with Operational Tasks</h3>
												<p>
                                                    Our operations team will work closely with you to ensure that the activities of your organization run efficiently. Each team member is highly organized with strong administrative skills, and knowledgeable of your industry to ensure helpful services to your customers.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/operations2.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Implementation of Processes and Procedures</h3>
												<p>
                                                    eFlex team members are dedicated to implementing processes and help you improve productivity and efficiency. We understand how processes involve multiple departments so we put emphasis on effective communication in executing guidelines and procedures.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/operations3.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Tracking and Reporting</h3>
												<p>
                                                    eFlex contributes to operational success by tracking trends and analyzing your company’s data in real-time. We provide operational reporting solutions that integrate reports and data visualizations to help you track progress, enhance productivity, and make key decisions based on day-today analytics and business intelligence (BI).
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Contact/>
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'Operational',
	data() {
		return {

		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
